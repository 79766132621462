<template>
	<div class="animated fadeIn">
		<b-card title="User Inventory Scans Report"
			sub-title="Summary of a user's latest participation on existing inventory sessions">
			<b-container class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<b-card>
					<b-row>
						<b-col md="12" sm="12" class="mt-1 mb-2">
							<b>FILTER OPTIONS</b>
						</b-col>
					</b-row>

					<b-row no-gutters>
						<b-col lg="4" md="10" sm="12" class="mr-2">
							<b-form-group label="User" label-for="User" description="Person who scans the asset">
								<v-select name="User" class="style-chooser" label="text"
									:options="filterByOptions.userItems" :reduce="(user) => user.value"
									v-model="filterBy.user">
									<template v-slot:no-options="{ search, searching }">
										<template v-if="searching">
											No results found for
											<em>
												<strong>{{ search }}</strong>
											</em>
										</template>
										<em :style="{ opacity: 0.5 }" v-else>
											Start typing to search for a user
										</em>
									</template>
								</v-select>
							</b-form-group>
						</b-col>
						<b-col lg="4" md="5" sm="12" class="mr-2">
							<b-form-group label="Employee No" description="Person's employee no or ID who scans the asset">
								<b-form-input name="Employee No" type="search" v-model="filterBy.employeeNo" placeholder />
							</b-form-group>
						</b-col>
						<b-col lg="4" md="5" sm="12" class="mr-2">
							<b-form-group label="Inventory Date From">
								<b-form-datepicker v-model="filterBy.dateFrom" locale="en" reset-button
									label-reset-button="Clear" :date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}" :date-disabled-fn="dateFromDisabled" />
							</b-form-group>
						</b-col>
						<b-col lg="4" md="5" sm="12" class="mr-2">
							<b-form-group label="Inventory Date To">
								<b-form-datepicker v-model="filterBy.dateTo" locale="en" reset-button
									label-reset-button="Clear" :date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}" :date-disabled-fn="dateFromDisabled" />
							</b-form-group>
						</b-col>

						<b-col lg="4" md="5" sm="12" class="mr-2">
							<b-form-group label="Asset Code"
								description="Scanned asset code from the existing inventory sessions">
								<b-form-input name="Asset Code" type="search" v-model="filterBy.assetCode"
									v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }" placeholder />
								<span v-show="errors.has('Asset Code')" class="help-block">{{
									errors.first('Asset Code')
								}}</span>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row no-gutters>
						<b-col sm="12">
							<b-button class="mr-1" variant="success" @click="onGenerate">
								Generate
							</b-button>
							<b-button class="mr-1" variant="primary" @click="resetFilters">
								Reset
							</b-button>
						</b-col>
					</b-row>
				</b-card>

				<div v-if="items.length > 0">
					<div class="btn-table-options">
						<b-row>
							<b-col sm="6" md="3">
								<b-dropdown text=" Select Actions " variant="dark" slot="append">
									<b-dropdown-item>
										<json-excel :data="exportData" :fields="exportFields" type="xls"
											:name="fileName + '.xls'">Export Report in Excel</json-excel>
									</b-dropdown-item>
									<b-dropdown-item>
										<json-excel :data="exportData" :fields="exportFields" type="csv"
											:name="fileName + '.csv'">Export Report to CSV</json-excel>
									</b-dropdown-item>
								</b-dropdown>
							</b-col>
							<b-col sm="6" md="3" offset-md="6" class="my-1 text-md-right">
								<b-input-group prepend="Show" append="/ Page">
									<b-form-select :options="pageOptions" v-model="perPage" />
								</b-input-group>
							</b-col>
						</b-row>
					</div>
					<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
						:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
						:sort-direction="sortDirection" responsive />
					<b-row>
						<b-col md="8" sm="12" class="my-1">
							<span class="totalDisplay">Total: {{ totalRows }}</span>
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
								class="my-0" />
						</b-col>
					</b-row>
				</div>
				<div v-else>
					<b-card> No Results Found. </b-card>
				</div>
			</b-container>
		</b-card>
	</div>
</template>

<script>
// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import reportApi from '@/api/reportApi';

// Others
import JsonExcel from 'vue-json-excel';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'user-scanned-inventory-report',
	components: { JsonExcel, Loading },
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'user',
					sortable: false,
				},
				{
					key: 'email',
					sortable: false,
				},
				{
					key: 'assetCode',
					sortable: true,
				},
				{
					key: 'inventoryLocation',
					sortable: false,
				},
				{
					key: 'inventorySessionId',
					sortable: false,
				},
				{
					key: 'inventoryDate',
					sortable: false,
				},
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				user: config.userDefaultValue,
				employeeNo: null,
				dateFrom: null,
				dateTo: null,
				assetCode: '',
			},
			filterBy: {
				user: config.userDefaultValue,
				employeeNo: null,
				dateFrom: null,
				dateTo: null,
				assetCode: '',
			},
			prevFilterBy: null,

			filterByOptions: {
				userItems: [],
			},

			allUsersObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				User: 'user',
				Email: 'email',
				'Asset Code': 'assetCode',
				'Inventory Location': 'inventoryLocation',
				'Inventory Session ID': 'inventorySessionId',
				'Inventory Date': 'inventoryDate',
			};
		},

		fileName() {
			let filename = 'UserScanSummary';
			if (this.filterBy.user.id) {
				filename += '_' + this.filterBy.user.id;
			}
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return `${filename}_${DateUtil.getDateInMMDDYYYYFormat(currTimeStamp)}`;
		},
	},
	async mounted() {
		// show loading indicator
		this.isLoading = true;

		try {
			this.allUsersObj = this.$store.getters.users;
			let userItems = DropDownItemsUtil.retrieveActiveUserItems(
				this.allUsersObj
			);
			this.filterByOptions.userItems = userItems;

			this.resetFilters();
		} catch (error) {
			this.$toaster.error('Error loading data. Please reload the page again.');
		}

		// hide loading indicator
		this.isLoading = false;
	},
	methods: {
		resetFilters() {
			this.items = [];
			this.filterBy = Object.assign({}, this.defaultFilterBy);

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},

		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},
		isValidAssetCode(assetCode) {
			let assetTypesObj = this.$store.getters.assetTypes;
			return (
				_.isEmpty(assetCode) ||
				ValidationUtil.isValidAssetCode(assetTypesObj, assetCode)
			);
		},
		async validateFilter() {
			let isValid = await this.$validator.validateAll();

			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return isValid;
			}

			if (!this.filterBy.user.id && !this.filterBy.employeeNo) {
				this.$toaster.warning('User or Employee No is required.');
				isValid = false;
			} else if (
				(_.isEmpty(this.filterBy.dateFrom) &&
					!_.isEmpty(this.filterBy.dateTo)) ||
				(!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))
			) {
				this.$toaster.warning(
					'Invalid Date Range. Date From and Date To must both have value.'
				);
				isValid = false;
			} else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning(
					'Invalid Date Range. Date From must be less than Date To.'
				);
				isValid = false;
			} else if (
				DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90
			) {
				this.$toaster.warning(
					'Invalid Date Range. Data range is allowed up to 90 days difference.'
				);
				isValid = false;
			} else if (!this.isValidAssetCode(this.filterBy.assetCode)) {
				this.$toaster.warning(
					`Invalid Asset Code. "${this.filterBy.assetCode}" doesn't follow any of your asset tagging format.`
				);
				isValid = false;
			}

			return isValid;
		},
		getReportParameters() {
			let filter = Object.assign({}, this.filterBy);
			filter.companyId = this.loggedUserCompany.id;

			if (this.filterBy.dateFrom) {
				filter.fromTimestamp = DateUtil.startDateTimeStamp(
					new Date(this.filterBy.dateFrom)
				);
			}
			if (this.filterBy.dateTo) {
				filter.toTimestamp = DateUtil.endDateTimeStamp(
					new Date(this.filterBy.dateTo)
				);
			}

			return filter;
		},
		async onGenerate() {
			let isValid = await this.validateFilter();
			if (!isValid) {
				return;
			}

			// Reset items
			this.items = [];
			// Show loader
			this.isLoading = true;

			try {
				let view = this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY;
				let reportParam = this.getReportParameters();
				let { data } = await reportApi.getUserInventoryScansReport(
					reportParam,
					view,
					this.loggedUser.id
				);

				if (data.isSuccess) {
					let items = data.items;
					if (!items || _.size(items) === 0) {
						this.$toaster.warning('No Results found.');
					} else {
						this.items = items;
						this.totalRows = this.items.length;
					}

					// Hide loader
					this.isLoading = false;
					// Mark the prevFilter
					this.prevFilter = Object.assign({}, this.filterBy);
				} else {
					this.$toaster.error(
						'Error loading data. Please reload the page again.'
					);
					// hide loading indicator
					this.isLoading = false;
				}
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);

				// hide loading indicator
				this.isLoading = false;
			}
		},
	},
};
</script>

<style scoped></style>
